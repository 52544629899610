import React, { Component } from 'react'



export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
  api_url: "http://caso.localhost.com/api/",
  // api_url: "https://casoapi.greenars.com.co/api/",
}
:
{
  api_url: "https://api.casokitchens.app/api/",
}