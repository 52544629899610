import React, { ReactNode, useEffect, useState } from 'react';

import Projects from 'components/Projects/Projects';

import {BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import ProjectDetail from './components/Projects/Dashboard/ProjectDashboard';

import ProjectEstimates from './components/Projects/ProjectEstimates';
import ProjectEstimatesEditor from './components/Projects/ProjectEstimatesEditor';
import ProjectEditor from './components/Projects/ProjectEditor';
import ProductList from 'components/Products/ProductList';
import ProductEditor from 'components/Products/ProductEditor';
import EstimateProducts from 'components/EstimateProducts/EstimateProducts';
import EstimateProductsEditor from 'components/EstimateProducts/EstimateProductsEditor';
import MiniDrawer from 'components/tests';
import TaskList from 'components/Tasks/TaskList';
import TaskEditor from 'components/Tasks/TaskEditor';
import ProjectClient from 'components/Projects/ProjectClient';
import ProjectQuotes from 'components/Projects/Quotes/QuotesList';
import ProjectQuotesEditor from 'components/Projects/Quotes/QuotesEditor';
import FilesBrowser from 'components/Files/FilesBrowser';
import UserList from 'components/Users/UserList';
import UserEditor from 'components/Users/UserEditor';
import Dashboard from 'components/Dashboard/Dashboard';

import Cookies from 'universal-cookie';
import AccountEditor from 'components/Account/AccountEditor';
import OrderList from 'components/Orders/OrderList';
import OrderEditor from 'components/Orders/OrderEditor';
import OrderIncoming from 'components/Orders/OrderIncoming';
import ClientList from 'components/Clients/ClientList';
import ClientEditor from 'components/Clients/ClientEditor';
import ScheduleView from 'components/Tasks/ScheduleView';
import SupplierList from 'components/Suppliers/SuppliersList';
import SupplierEditor from 'components/Suppliers/SuppliersEditor';
import WorkforceList from 'components/Workforce/WorkforceList';
import WorkforceEditor from 'components/Workforce/WorkforceEditor';
import OutgoingList from 'components/Products/Outgoing/OutgoingList';
import OutgoingEditor from 'components/Products/Outgoing/OutgoingEditor';
import PointsList from 'components/Points/PointsList';
import PointsEditor from 'components/Points/PointsEditor';
import PerformanceList from 'components/Users/Performance/PerformanceList';
import SuperviseWorkersList from 'components/SuperviseWorkers/SuperviseWorkersList';
import WorkerTimmingList from 'components/Users/Timming/WorkerTimmingList';
import WorkerTimmingEditor from 'components/Users/Timming/WorkerTimmingEditor';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import ProjectConfigurator from 'components/Projects/ProjectConfig';
import { PaymentList } from 'components/Payment/PaymentList';

import { MachineryList } from 'components/Machinery/MachineryList';
import MachineryEditor from 'components/Machinery/MachineryEditor';
import ApplianceList from 'components/Appliance/ApplianceList';
import ApplianceEditor from 'components/Appliance/ApplianceEditor';
import PopupViewer from 'elements/PopupViewer';
import { NotesList } from 'components/Notes/NotesList';
import { NotesEditor } from 'components/Notes/NotesEditor';
import { PayrollList } from 'components/Payroll/PayrollList';
import QuotesEditorV1 from 'components/Projects/Quotes/V1/QuoteEditorV1';
import QuotesEditor from 'components/Projects/Quotes/QuotesEditor';

const componentMap: any = {
  Projects: Projects,
  ProjectDetail: ProjectDetail,
  ProjectEditor: ProjectEditor,
  ProjectEstimates: ProjectEstimates,
  ProjectEstimatesEditor: ProjectEstimatesEditor,
  ProductList: ProductList,
  ProductEditor: ProductEditor,
  EstimateProducts: EstimateProducts,
  EstimateProductsEditor: EstimateProductsEditor,
  ProjectQuotes: ProjectQuotes,
  ProjectQuotesEditor: ProjectQuotesEditor,
  FilesBrowser: FilesBrowser,
  WorkerTimmingList: WorkerTimmingList,
  WorkerTimmingEditor: WorkerTimmingEditor,
  TaskList: TaskList,
  TaskEditor: TaskEditor,
  ProjectClient: ProjectClient,
  PaymentList: PaymentList,
  OutgoingList: OutgoingList,
  OutgoingEditor: OutgoingEditor,
  UserList: UserList,
  UserEditor: UserEditor,
  AccountEditor: AccountEditor,
  OrderList: OrderList,
  OrderEditor: OrderEditor,
  OrderIncoming: OrderIncoming,
  Dashboard: Dashboard,
  MiniDrawer: MiniDrawer,
  ClientList: ClientList,
  ClientEditor: ClientEditor,
  SupplierList: SupplierList,
  SupplierEditor: SupplierEditor,
  ScheduleView: ScheduleView,
  PerformanceList: PerformanceList,
  WorkforceList: WorkforceList,
  WorkforceEditor: WorkforceEditor,
  PointsList: PointsList,
  PointsEditor: PointsEditor,
  SuperviseWorkersList: SuperviseWorkersList,
  ProjectConfigurator: ProjectConfigurator,
  MachineryList: MachineryList,
  MachineryEditor: MachineryEditor,
  ApplianceList: ApplianceList,
  AppliancesEditor: ApplianceEditor,
  PopupViewer: PopupViewer,
  NotesList: NotesList,
  NotesEditor: NotesEditor,
  PayrollList: PayrollList,
};
export default function AppRoutes(props:any) {
  
  const {
    setMenuTopLeftContent,
    setLoading,
    handleLogout,
  } = props;


  /**
   * FIRST METHOD, EASY VALIDATION AF ROLE
   */
  const isAdmin = () => {
    return localStorage.getItem('role_id') === "1";
  }


  /**
   * THIRD METHOD, ENDPOINT VALIDATION AND COMPONENT DECODER FUNCTION
   */
  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    // setRoutes([...fakeAPI]);
    const cookies = new Cookies();
    
    axios.get(ProjectConfig.api_url, {
      headers: {
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'get_user_permissions',
      }
    })
    .then((response) => {
      console.log(response);
      // handleLogout();
      if (typeof response.data === 'string') {
        handleLogout();
      } else {
        setRoutes(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }


  return ( 
      <Routes>
        
        {routes.map((route, index) => {
          let element: ReactNode;

          // if outside return for possible future extra validations
          if (route.navigate) {
            element = <Navigate to={route.navigate} />;
          } else {
            const Component = componentMap[route.component];
            element = <Component
              setLoading={setLoading}
              setMenuTopLeftContent={setMenuTopLeftContent}
            />
          }

          return(
            <Route 
              key={index} 
              path={route.url} 
              element={element} 
            />
        )})}


        <Route path="/projects/:project_id/config" element={<ProjectConfigurator setLoading={setLoading} setMenuTopLeftContent={setMenuTopLeftContent} />} /> 
        <Route path="/projects/:project_id/config" element={<ProjectEditor setLoading={setLoading} setMenuTopLeftContent={setMenuTopLeftContent} />} /> 


        <Route path="/machinery" element={<MachineryList setLoading={setLoading} />} /> 
        <Route path="/machinery/add" element={<MachineryEditor setLoading={setLoading} />} /> 
        <Route path="/machinery/edit/:machinery_id" element={<MachineryEditor setLoading={setLoading} />} /> 

        <Route path="/appliance" element={<ApplianceList setLoading={setLoading} />} />
        <Route path="/appliance/add" element={<ApplianceEditor setLoading={setLoading} />} />

        <Route path="/appliance/edit/:appliance_id" element={<ApplianceEditor setLoading={setLoading} />} />

        <Route path="/notes" element={<NotesList setLoading={setLoading} />} />
        <Route path="/notes/edit/:note_id" element={<NotesEditor setLoading={setLoading} />} />
        <Route path="/projects/:project_id/notes" element={<NotesEditor setLoading={setLoading} />} />

        <Route path="/payroll" element={<PayrollList setLoading={setLoading} />} />

        <Route path="/projects/:project_id/quotes/add/V1" element={<QuotesEditorV1 setLoading={setLoading} />} />
        <Route path="/projects/:project_id/quotes/edit/:quote_id/V1" element={<QuotesEditorV1 setLoading={setLoading} />} />
        <Route path="/projects/:project_id/quotes/edit/:quote_id/:sequence_id" element={<QuotesEditor setLoading={setLoading} />} />

        <Route path="/dashboard" element={<Dashboard setLoading={setLoading} />} />


      </Routes>
  );
}
