import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from '@mui/material';
import { ExpandMore, ExpandLess, Circle } from '@mui/icons-material';

import {
    ITypeCost,
    QuotesContext
} from '../QuoteContextProvider';
import QuoteServiceSummaryBox from './QuoteServiceSummaryBox';

export default function QuoteTypeSummaryBox( props: any ) {

    const {
        fixNumberValue,
        quoteCost,
        costDict,
        services_types,
        updateQuoteCostType,
        loadingFields,
        setLoadingFields,
    } = useContext(QuotesContext);

    const {
        type,
    } = props;

    const [typeCapitalize, setTypeCapitalize] = useState<string>('');
    const [typeCost, setTypeCost] = useState<ITypeCost | null>(null);
    const [profit, setProfit] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0);

    const [openServicesSummaries, setOpenServicesSummaries] = useState<boolean>(false);

    const refProfitPercentageHandler = useRef<any>();
    const refTotalCostHandler = useRef<any>();

    const [updatedProfitPercentage, setUpdatedProfitPercentage] = useState<boolean>(true);
    const [updatedProfitPercentageSafe, setUpdatedProfitPercentageSafe] = useState<boolean>(true);
    const [percentageToUpdate, setPercentageToUpdate] = useState<number>(0);
    const [updatedTotalCost, setUpdatedTotalCost] = useState<boolean>(true);

    useEffect(() => {
        if (type) {
            // console.log('type', type);
            setTypeCapitalize(type.serviceType.substring(0,1).toUpperCase() + type.serviceType.substring(1).toLowerCase());
            setTypeCost(quoteCost[type.serviceType]);

            setUpdatedProfitPercentage(true);
            setUpdatedTotalCost(true);
            setLoadingFields(false);
        }
    }, [type, quoteCost]);

    useEffect(() => {
        if (typeCost !== null) {
            let profit = 0;
            if (type.with_profit && typeCost.profit_percentage !== undefined) {
                profit = ((type.with_profit ? (typeCost.profit_percentage || 0) : 0) / 100) * typeCost.company_cost;
            } else {
                profit = typeCost.profit_value || 0;
            }
            setProfit(profit);
            setTotalCost(typeCost.material_cost + typeCost.workforce_cost + profit);

            if (!updatedProfitPercentageSafe) {
                if (Math.round(typeCost.profit_percentage || percentageToUpdate) == Math.round(percentageToUpdate)) {
                    setUpdatedProfitPercentageSafe(true);
                }
            }
        } else {
            updateQuoteCostType(
                {},
                type.serviceType,
            );
        }
    }, [typeCost]);

    const onChangeProfitPercentage = () => {
        handlePercentageChange(typeCost?.profit_percentage);
    }

    const onChangeTotalCost = () => {
        if (typeCost === null) return;

        if (type.with_profit) {
            handlePercentageChange(((totalCost / (typeCost?.company_cost || totalCost)) - 1) * 100);
        } else {
            handleProfitChange(totalCost - (typeCost.material_cost || 0) - (typeCost.workforce_cost || 0));
        }
    }

    useEffect(() => {
        refProfitPercentageHandler.current = onChangeProfitPercentage;
    }, [onChangeProfitPercentage]);

    useEffect(() => {
        refTotalCostHandler.current = onChangeTotalCost;
    }, [onChangeTotalCost]);

    const overrideProfitPercentage = () => {
        if (updatedProfitPercentage) return;

        refProfitPercentageHandler.current?.();
    };

    const overrideTotalCost = () => {
        if (updatedTotalCost) return;

        refTotalCostHandler.current?.();
    };

    const handlePercentageChange = (value: any) => {
        setUpdatedProfitPercentageSafe(false);
        setPercentageToUpdate(value);
        updateQuoteCostType(
            {
                profit_percentage: value,
            },
            type.serviceType,
        );
    };

    const handleProfitChange = (value: any) => {
        updateQuoteCostType(
            {
                profit_value: value,
                profit_fixed: 0,
            },
            type.serviceType,
        );
    };

    return (
        <div>
        {/* <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}> */}
            {(typeCost === null || (totalCost == 0 && (!typeCost.profit_value || typeCost?.profit_value > 0))) ?
                null
            : (
            <div className="summary_section">
                <span
                    id={`SummaryTitle${typeCapitalize}`}
                    className="quoteSummaryTitle"
                >
                    {typeCapitalize}

                    <IconButton
                        size="small"
                        sx={{ color: '#fff' }}
                        className="padding-off end-icon"
                        onClick={() => setOpenServicesSummaries(!openServicesSummaries)}
                    >
                        {!openServicesSummaries ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                </span>

                {!openServicesSummaries ?
                <>
                    <div className="summary_row">
                        <div className="summary_row_left">Material</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(typeCost.material_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>


                    <div className="summary_row">
                        <div className="summary_row_left">Workforce</div>
                        <div className="summary_row_right">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(typeCost.workforce_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    <div className="summary_row summary_line_top">
                        <div className="summary_row_left">Company cost</div>
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(typeCost.company_cost || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>

                    {type.with_profit &&
                    <div className="summary_row">
                        <div className="summary_row_left">
                            <TextField
                                name={`${type.serviceType}_profit_percentage`}
                                size="small"
                                className="quote_profit_percentage"
                                InputLabelProps={{
                                    style: { fontSize: '12px' }
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '12px',
                                        width: '60px',
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!updatedProfitPercentage && !updatedProfitPercentageSafe && true ?
                                                <Tooltip title={"Press enter or leave the field to confirm"}>
                                                    <Circle
                                                        color="secondary"
                                                        className='field_badge'
                                                    />
                                                </Tooltip>
                                                :
                                                null
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    style: {
                                        fontSize: '12px', 
                                        textAlign: 'end',
                                        width: '30px',
                                        padding: '3px',
                                    }
                                }}
                                disabled={!updatedProfitPercentageSafe}
                                value={typeCost.profit_percentage}
                                onChange={(e: { target: { value: any; }; }) => {
                                    setTypeCost({
                                        ...typeCost,
                                        profit_percentage: fixNumberValue(e.target.value, true, false, true),
                                    });

                                    setUpdatedProfitPercentage(false);
                                }}
                                onBlur={overrideProfitPercentage}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        overrideProfitPercentage();
                                    }
                                }}
                            />
                            % Profit
                        </div>
                        
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <>${(profit || 0).toLocaleString()}</>
                            }
                        </div>
                    </div>
                    }

                    {(!type.with_profit && typeCost.profit_value !== undefined) &&
                    <>
                        {typeCost.profit_value > 0 &&
                            <div className="summary_row">
                                <div className="summary_row_left">Profit</div>
                                <div className="sidebar_body_row_value">
                                    {loadingFields ?
                                        <CircularProgress color="secondary" size="0.8rem" /> :
                                        <>${(typeCost.profit_value || 0).toLocaleString()}</>
                                    }
                                </div>
                            </div>
                        }
                        {typeCost.profit_value < 0 &&
                            <div className="summary_row">
                                <div className="summary_row_left">Discount</div>
                                <div className="sidebar_body_row_value">
                                    {loadingFields ?
                                        <CircularProgress color="secondary" size="0.8rem" /> :
                                        <>${((typeCost.profit_value || 0) * -1).toLocaleString()}</>
                                    }
                                </div>
                            </div>
                        }
                    </>
                    }

                    <div className="summary_row quote_total_row">
                        <div className="summary_row_left">Total</div>
                        <div className="sidebar_body_row_value">
                            {loadingFields ?
                                <CircularProgress color="secondary" size="0.8rem" /> :
                                <div className="box100 align_right">
                                    $
                                    <TextField
                                        name={`${type.serviceType}_total_cost`}
                                        size="small"
                                        className="quote_total_cost box50"
                                        InputLabelProps={{
                                            style: { fontSize: '12px' }
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: '12px',
                                            },
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    {!updatedTotalCost && true ?
                                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                                            <Circle
                                                                color="secondary"
                                                                className='field_badge'
                                                            />
                                                        </Tooltip>
                                                        :
                                                        null
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                fontSize: '12px', 
                                                textAlign: 'end',
                                                padding: '3px',
                                            }
                                        }}
                                        value={totalCost}
                                        onChange={(e: { target: { value: any; }; }) => {
                                            const fixValue = fixNumberValue(e.target.value, true, false, true);

                                            if (typeof fixValue === 'string' && fixValue.startsWith('0.')) {
                                                setTotalCost(1);
                                            } else {
                                                setTotalCost(fixNumberValue(e.target.value, true, false, true) || 1);
                                            }

                                            setUpdatedTotalCost(false);
                                        }}
                                        onBlur={overrideTotalCost}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                overrideTotalCost();
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    {Object.keys(costDict)
                    .filter((item: any) => costDict[item].serviceType === type.serviceType)
                    .map((serviceIndex: any, costIndex: number) => {
                        return(
                            <div key={costIndex}>
                                <QuoteServiceSummaryBox
                                    serviceIndex={serviceIndex}
                                />
                            </div>
                        );
                    })}
                </>
                }

            </div>
            )}
        </div>
    );

}