import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IMeasureExplodedView, QuotesContext } from '../QuoteContextProvider';
import { Button, CircularProgress, InputAdornment, TextField, Tooltip, debounce } from '@mui/material';
import { Circle, DeleteOutlineOutlined } from '@mui/icons-material';

const defaultValues: IMeasureExplodedView = {
    measure: '',
    width: 0,
    length: 0,
    quantity: 0,
    sq_ft: 0,
    description: '',
    sheets: 0,
}

export default function ExplodedMeasureRow ( props: any ) {

    const {
        buildUniqueId,
        fixNumberValue,
        measureDict,
        updateMeasure,
        deleteMeasure,
        setLoadingFields,
    } = useContext(QuotesContext);

    const {
        serviceIndex,
        category,
        itemIndex,
        measureIndex,
        serviceType,
        key = undefined,
    } = props;

    const [item, setItem] = useState<IMeasureExplodedView>(defaultValues);
    const refMeasureHandler = useRef<any>();
    const refWidthHandler = useRef<any>();
    const refLengthHandler = useRef<any>();
    const refQuantityHandler = useRef<any>();
    const refDescriptionHandler = useRef<any>();
    const refSheetsHandler = useRef<any>();

    const [updatedMeasure, setUpdatedMeasure] = useState<boolean>(true);
    const [updatedWidth, setUpdatedWidth] = useState<boolean>(true);
    const [updatedLength, setUpdatedLength] = useState<boolean>(true);
    const [updatedQuantity, setUpdatedQuantity] = useState<boolean>(true);
    const [updatedDescription, setUpdatedDescription] = useState<boolean>(true);
    const [updatedSheets, setUpdatedSheets] = useState<boolean>(true);

    useEffect(() => {
        if (props) {
            const measureList = measureDict[buildUniqueId(serviceIndex, category)];
            if (measureList) {
                const productList = measureList[itemIndex];
                if (productList) {
                    updateItem(productList[measureIndex]);

                } else {
                    initValues();
                }
                
            } else {
                initValues();
            }

        }
    }, [props]);

    const initValues = () => {
        setItem({...defaultValues});
        setUpdatedMeasure(true);
        setUpdatedWidth(true);
        setUpdatedLength(true);
        setUpdatedQuantity(true);
        setUpdatedDescription(true);
        setUpdatedSheets(true);
        setLoadingFields(false);
    };

    const updateItem = (updatedItem: IMeasureExplodedView) => {
        if (!updatedMeasure && updatedItem.measure != item.measure) return;

        if (!updatedWidth && updatedItem.width != item.width) return;

        if (!updatedLength && updatedItem.length != item.length) return;

        if (!updatedQuantity && updatedItem.quantity != item.quantity) return;

        if (!updatedDescription && updatedItem.description != item.description) return;

        if (!updatedSheets && updatedItem.sheets != item.sheets) return;

        setItem((prevState) => ({
            ...prevState,
            ...updatedItem,
        }));
        setUpdatedMeasure(true);
        setUpdatedWidth(true);
        setUpdatedLength(true);
        setUpdatedQuantity(true);
        setUpdatedDescription(true);
        setUpdatedSheets(true);
        setLoadingFields(false);
    };

    const onChangeMeasure = () => {
        handleValueChange(item.measure, 'measure');
    }

    const onChangeWidth = () => {
        handleValueChange(item.width, 'width', true);
    }

    const onChangeLength = () => {
        handleValueChange(item.length, 'length', true);
    }

    const onChangeQuantity = () => {
        handleValueChange(item.quantity, 'quantity', true);
    }

    const onChangeDescription = () => {
        handleValueChange(item.description, 'description');
    }

    const onChangeSheets = () => {
        handleValueChange(item.sheets, 'sheets');
    }

    useEffect(() => {
        refMeasureHandler.current = onChangeMeasure;
    }, [onChangeMeasure]);

    useEffect(() => {
        refWidthHandler.current = onChangeWidth;
    }, [onChangeWidth]);

    useEffect(() => {
        refLengthHandler.current = onChangeLength;
    }, [onChangeLength]);

    useEffect(() => {
        refQuantityHandler.current = onChangeQuantity;
    }, [onChangeQuantity]);

    useEffect(() => {
        refDescriptionHandler.current = onChangeDescription;
    }, [onChangeDescription]);

    useEffect(() => {
        refSheetsHandler.current = onChangeSheets;
    }, [onChangeSheets]);

    const overrideMeasure = () => {
        if (updatedMeasure) return;

        refMeasureHandler.current?.();
    }

    const overrideWidth = () => {
        if (updatedWidth) return;

        refWidthHandler.current?.();
    }

    const overrideLength = () => {
        if (updatedLength) return;

        refLengthHandler.current?.();
    }

    const overrideQuantity = () => {
        if (updatedQuantity) return;

        refQuantityHandler.current?.();
    }

    const overrideDescription = () => {
        if (updatedDescription) return;

        refDescriptionHandler.current?.();
    }

    const overrideSheets = () => {
        if (updatedSheets) return;

        refSheetsHandler.current?.();
    }

    const calcSqFt = () => {
        if (
            item.width &&
            item.length &&
            item.width >= 0 &&
            item.length >= 0
        ) {
            const sq_ft = Math.ceil((item.width * item.length / 144));

            setItem({
                ...item,
                sq_ft,
            });
        }
    }

    useEffect(() => {
        calcSqFt();
    }, [item.width, item.length]);

    const handleValueChange = (value: any, field: string, with_sqft: boolean = false) => {
        const newMeasureChanges = {
            [field]: value,
        };

        if (with_sqft) {
            newMeasureChanges['sq_ft'] = item.sq_ft;
        }

        updateMeasure(newMeasureChanges, serviceIndex, category, itemIndex, measureIndex);
    }

    return (
        <>
            <tr key={key}>
                <td className="measuresTextTd">
                    {serviceType !== 'stone' ?
                    <TextField
                        value={item.measure}
                        type="text"
                        size="small"
                        label="Measure"
                        fullWidth
                        inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedMeasure && true ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        onChange={(e) => {
                            setItem((prevState: IMeasureExplodedView) => ({
                                ...prevState,
                                measure: e.target.value,
                            }));

                            setUpdatedMeasure(false);
                        }}
                        onBlur={overrideMeasure}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideMeasure();
                            }
                        }}
                    />
                    :
                    <>
                        <TextField
                            value={item.width}
                            type="text"
                            size="small"
                            label="Width"
                            fullWidth
                            inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedWidth && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: 12,
                                },
                            }}
                            onChange={(e) => {
                                setItem((prevState: IMeasureExplodedView) => ({
                                    ...prevState,
                                    width: fixNumberValue(e.target.value, true, false, true),
                                }))

                                setUpdatedWidth(false);
                            }}
                            onBlur={overrideWidth}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideWidth();
                                }
                            }}
                        />

                        <TextField
                            value={item.length}
                            type="text"
                            size="small"
                            label="Length"
                            fullWidth
                            inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!updatedLength && true ?
                                            <Tooltip title={"Press enter or leave the field to confirm"}>
                                                <Circle
                                                    color="secondary"
                                                    className='field_badge'
                                                />
                                            </Tooltip>
                                            :
                                            null
                                        }
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: 12,
                                },
                            }}
                            onChange={(e) => {
                                setItem((prevState: IMeasureExplodedView) => ({
                                    ...prevState,
                                    length: fixNumberValue(e.target.value, true, false, true),
                                }))

                                setUpdatedLength(false);
                            }}
                            onBlur={overrideLength}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    overrideLength();
                                }
                            }}
                        />
                    </>
                    }
                </td>

                <td className="MeasureQuantityTd">
                    <TextField
                        value={item.quantity}
                        type="text"
                        size="small"
                        label="Quantity"
                        fullWidth
                        inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedQuantity && true ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        onChange={(e) => {
                            setItem((prevState: IMeasureExplodedView) => ({
                                ...prevState,
                                quantity:
                                    fixNumberValue( e.target.value, false, false, true),
                            }))

                            setUpdatedQuantity(false);
                        }}
                        onBlur={overrideQuantity}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideQuantity();
                            }
                        }}
                    />
                </td>

                {serviceType === 'stone' &&
                <td className="MeasureQuantityTd">
                    <TextField
                        value={(item.sq_ft || 0) * (item.quantity || 0)}
                        type="text"
                        size="small"
                        label="Sq Ft"
                        fullWidth
                        inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        disabled
                    />
                </td>
                }

                <td>
                    <TextField
                        value={item.description}
                        type="text"
                        size="small"
                        label="Description"
                        fullWidth
                        inputProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedDescription && true ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        onChange={(e) => {
                            setItem((prevState: IMeasureExplodedView) => ({
                                ...prevState,
                                description: e.target.value,
                            }))

                            setUpdatedDescription(false);
                        }}
                        onBlur={overrideDescription}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideDescription();
                            }
                        }}
                    />
                </td>

                <td className="MeasureQuantityTd">
                    <TextField
                        value={item.sheets}
                        type="text"
                        size="small"
                        label={ serviceType !== 'stone' ? "Sheets" : "Slabs" }
                        fullWidth
                        inputProps={{
                            style: {
                                textAlign: "center",
                                fontSize: 12,
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!updatedSheets && true ?
                                        <Tooltip title={"Press enter or leave the field to confirm"}>
                                            <Circle
                                                color="secondary"
                                                className='field_badge'
                                            />
                                        </Tooltip>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 12,
                            },
                        }}
                        onChange={(e) => {
                            setItem((prevState: IMeasureExplodedView) => ({
                                ...prevState,
                                sheets:
                                    fixNumberValue(e.target.value, true, false, true),
                            }))

                            setUpdatedSheets(false);
                        }}
                        onBlur={overrideSheets}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                overrideSheets();
                            }
                        }}
                    />
                </td>

                <td className="deleteButtonTd">
                    <Button
                        onClick={() => {
                            deleteMeasure(
                                serviceIndex,
                                category,
                                itemIndex,
                                measureIndex
                            );
                        }}
                    >
                        <DeleteOutlineOutlined />
                    </Button>
                </td>
            </tr>
        </>
    );

}